import React, { useState } from "react";
import CicInsurance from "./components/CicInsurance/CicInsurance";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TopNav from "./components/TopNav";
import Footer from "./components/Footers/Footer";
import Table1 from "./components/Benefit/Table";

function App() {
  return (
    <div style={{ display: "flex", flexDirection: "row" }} >
    <CicInsurance/>
    </div>
  );
}

export default App;
