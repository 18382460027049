import React, { useState } from "react";
import { Button, Card, Container, Form, Table } from "react-bootstrap";

function CicInsurance() {
  const [value, setValue] = useState("");
  const [make, setMake] = useState("");
  const [year, setYear] = useState("");
  const [usage, setUsage] = useState("");
  const [premium, setPremium] = useState(null);
  const [premium1, setPremium1] = useState(null);
  const [premium2, setPremium2] = useState(null);
  const [cicbasic_premium, setCicbasic] = useState(null);
  const [dirbasic_premium, setDirbasic] = useState(null);
  const [amacobasic_premium, setAmacobasic] = useState(null);
  const [excess_protector, setExcessProtector] = useState(null);
  const [courtesybenefits, setCourtesyBenefits] = useState(null);
  const [pvt, setPvt] = useState(null);
  const [phf, setPhf] = useState(null);

  const calculatePremium = () => {
    // Here is the calculation logic
    let premium = 0;
    let phf = 0;
    let cicbasic_premium = 0;
    let dirbasic_premium = 0;
    let amacobasic_premium = 0;
    let premium1 = 0;
    let premium2 = 0;
    let dir_levies = 0;
    let cic_levies = 0;
    let amaco_levies = 0;
    let dir_pvt = 0;
    let cic_pvt = 0;
    let amaco_pvt = 0;
    let cic_benefits = 0;
    let dir_benefits = 0;
    let amaco_benefits = 0;
    let cic_stampduty = 40;
    let dir_stampduty = 40;
    let amaco_stampduty = 40;
    let dir_pvttotal = 0;
    let cic_pvttotal = 0;
    let amaco_pvttotal = 0;

    if (value >= 500000 && value <= 1200000) {
      premium = (value * 4) / 100;
      cicbasic_premium = premium;
      premium1 = (value * 3.5) / 100;
      dirbasic_premium = premium1;
      premium2 = (value * 4.1) / 100;
      amacobasic_premium = premium2;
      cic_benefits = premium;
      dir_benefits = premium1;
      amaco_benefits = premium2;
      cic_levies = (premium * 0.45) / 100;
      phf = cic_levies;
      dir_levies = (premium1 * 0.4) / 100;
      amaco_levies = (premium2 * 0.44) / 100;
      premium = cic_levies + premium;
      premium1 = dir_levies + premium1;
      premium2 = amaco_levies + premium2;
      premium = premium + cic_stampduty;
      premium1 = premium1 + dir_stampduty;
      premium2 = premium2 + amaco_stampduty;
      cic_pvt = (0.25 / 100) * value;
      dir_pvt = (0.25 / 100) * value;
      amaco_pvt = (0.25 / 100) * value;
      cic_pvttotal = cic_benefits + 2500 + 5000 + 6000;
      dir_pvttotal = dir_benefits + 2500 + 4500 + 6000;
      amaco_pvttotal = amaco_benefits + 2500 + 4500 + 6000;
      premium = (cic_pvttotal * 0.45) / 100 + cic_pvttotal;
      premium1 = (dir_pvttotal * 0.45) / 100 + dir_pvttotal;
      premium2 = (amaco_pvttotal * 0.45) / 100 + amaco_pvttotal;
      premium = premium + cic_stampduty;
      premium1 = premium1 + dir_stampduty;
      premium2 = premium2 + amaco_stampduty;
    } else if (value > 1200000 && value <= 1500000) {
      premium = (value * 3.5) / 100;
      premium1 = (value * 3.4) / 100;
      premium2 = (value * 3.6) / 100;
      dirbasic_premium = premium1;
      cicbasic_premium = premium;
      amacobasic_premium = premium2;
      cic_benefits = premium;
      dir_benefits = premium1;
      amaco_benefits = premium2;
      cic_levies = (premium * 0.45) / 100;
      dir_levies = (premium1 * 0.43) / 100;
      amaco_levies = (premium2 * 0.44) / 100;
      phf = cic_levies;
      phf = amaco_levies;
      premium = cic_levies + premium;
      premium1 = dir_levies + premium1;
      premium2 = amaco_levies + premium2;
      premium = premium + cic_stampduty;
      premium1 = premium1 + dir_stampduty;
      premium2 = premium2 + amaco_stampduty;
      cic_pvt = (0.25 / 100) * value;
      dir_pvt = (0.23 / 100) * value;
      amaco_pvt = (0.24 / 100) * value;
      cic_pvttotal = cic_pvt + 5000 + cic_benefits;
      dir_pvttotal = dir_pvt + 5000 + dir_benefits;
      amaco_pvttotal = amaco_pvt + 5000 + amaco_benefits;
      premium = (cic_pvttotal * 0.45) / 100 + cic_pvttotal;
      premium1 = (dir_pvttotal * 0.45) / 100 + dir_pvttotal;
      premium2 = (amaco_pvttotal * 0.46) / 100 + amaco_pvttotal;
      premium = premium + cic_stampduty;
      premium1 = premium1 + dir_stampduty;
      premium2 = premium2 + amaco_stampduty;
    } else if (value > 1500000) {
      premium = (value * 3) / 100;
      premium1 = (value * 2.9) / 100;
      premium2 = (value * 3.1) / 100;
      cic_benefits = premium;
      dir_benefits = premium1;
      amaco_benefits = premium2;
      cic_levies = (premium * 0.45) / 100;
      dir_levies = (premium1 * 0.43) / 100;
      amaco_levies = (premium2 * 0.44) / 100;
      phf = cic_levies;

      premium = cic_levies + premium;
      premium1 = dir_levies + premium1;
      premium2 = amaco_levies + premium2;
      premium = premium + cic_stampduty;
      premium1 = premium1 + dir_stampduty;
      premium2 = premium2 + amaco_stampduty;

      cic_pvt = (0.25 / 100) * value;
      dir_pvt = (0.23 / 100) * value;
      amaco_pvt = (0.25 / 100) * value;
      cic_pvttotal = cic_pvt + 5000 + cic_benefits;
      dir_pvttotal = dir_pvt + 5000 + dir_benefits;
      amaco_pvttotal = amaco_pvt + 5000 + amaco_benefits;
      premium = (cic_pvttotal * 0.45) / 100 + cic_pvttotal;
      premium1 = (dir_pvttotal * 0.45) / 100 + dir_pvttotal;
      premium2 = (amaco_pvttotal * 0.45) / 100 + amaco_pvttotal;
      premium = premium + cic_stampduty;
      premium1 = premium1 + dir_stampduty;
      premium2 = premium2 + amaco_stampduty;
    }

    if (make === "Toyota") {
      premium += 2250;
      premium1 += 2350;
      premium2 += 2450;
    } else if (make === "Honda") {
      premium += 2000;
      premium1 += 2000;
      premium2 += 2100;
    } else if (make === "Ford") {
      premium += 1350;
      premium1 += 1250;
      premium2 += 1350;
    }
    if (year >= 2000 && year <= 2010) {
      premium += 2000;
      premium1 += 2000;
      premium2 += 2000;
    } else if (year > 2010 && year <= 2020) {
      premium += 3150;
      premium1 += 3000;
      premium2 += 3200;
    } else if (year > 2020) {
      premium += 5000;
      premium1 += 4500;
      premium2 += 4500;
    }
    if (usage === "Personal") {
      premium += 1000;
      premium1 += 1000;
      premium1 += 1500;
    } else if (usage === "Business") {
      premium += 2000;
      premium1 += 2000;
      premium1 += 2500;
    }
    if (excess_protector === "excess_protector") {
      premium += cic_pvt;
      premium1 += dir_pvt;
      premium2 += amaco_pvt;
    } else if (excess_protector === null) {
      premium += 0;
      premium1 += 0;
      premium2 += 0;
    }

    if (pvt === "pvt") {
      premium = premium + cic_pvt;
      premium1 = premium1 + dir_pvt;
      premium2 = premium2 + amaco_pvt;
    } else if (pvt === null) {
      premium += 0;
      premium1 += 0;
      premium2 += 0;
    }
    setPremium(premium);
    setPremium1(premium1);
    setPremium2(premium2);
    setCicbasic(cicbasic_premium);
    setDirbasic(dirbasic_premium);
    setExcessProtector(excess_protector);
    setCourtesyBenefits(courtesybenefits);
    setPvt(pvt);
    setPhf(phf);
    setAmacobasic(amacobasic_premium);
  };

  return (
    <div>
      <Card style={{ width: "60rem" }}>
        <Card.Body className="card">
          <h1 className="header">Car Insurance Premium Calculator</h1>
          <label htmlFor="value" className="value">
            Value of Car:
          </label>
          <input
            type="number"
            id="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <br />
          <label htmlFor="make">Make of Car:</label>
          <select
            id="make"
            value={make}
            onChange={(e) => setMake(e.target.value)}
          >
            <option value="">--Select--</option>
            <option value="Toyota">Toyota</option>
            <option value="Honda">Honda</option>
            <option value="Ford">Ford</option>
            <option value="Mercedes benz">Mercedes benz</option>
            <option value="Land Rover">Land Rover</option>
          </select>
          <br />
          <label htmlFor="year">Year of Manufacturer:</label>
          <select
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">--Select--</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
          </select>
          <br />
          <label htmlFor="usage">Usage:</label>
          <select
            id="usage"
            value={usage}
            onChange={(e) => setUsage(e.target.value)}
          >
            <option value="">--Select--</option>
            <option value="Personal">Personal</option>
            <option value="Business">Business</option>
          </select>
          <br />
          <label htmlFor="excess_protector">Select Benefits:</label>
          <select
            id="excess_protector"
            value={excess_protector}
            onChange={(e) => setExcessProtector(e.target.value)}
          >
            <option value="">--Select--</option>
            <option value="excess_protector">Excess Protector</option>
          </select>

          <br />
          <label htmlFor="pvt"></label>
          <select id="pvt" value={pvt} onChange={(e) => setPvt(e.target.value)}>
            <option value="">--Select--</option>
            <option value="pvt">Political Violence & Terrorism</option>
          </select>
          <br />
          <label htmlFor="courtesybenefits"></label>
          <select
            id="courtesybenefits"
            value={courtesybenefits}
            onChange={(e) => setCourtesyBenefits(e.target.value)}
          >
            <option value="">--Select--</option>
            <option value="courtesybenefits">Courtesy Car Benefit</option>
          </select>
          <br />
          <button onClick={calculatePremium}>Calculate Premium</button>
          <br />
          <div>
            {premium !== null && (
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Total Premium</th>
                    <th>Basic Premium</th>
                    <th>Excess Protector</th>
                    <th>Windscreen</th>
                    <th>PH&F</th>
                    <th>Courtesy Car Benefit</th>
                    <th>Stamp Duty</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CIC </td>
                    <td> {premium}</td>
                    <td> {cicbasic_premium}</td>
                    <td> 5000</td>
                    <td> Free</td>
                    <td> {phf}</td>
                    <td> {phf}</td>
                    <td> 40 </td>
                    <td>
                      <Button href="https://www.placidinsurance.co.ke/product/cic-comprehensive/">
                        Buy Now
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {premium1 !== null && (
              <Table striped bordered hover className="table2">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Total Premium</th>
                    <th>Basic Premium</th>
                    <th>Excess Protector</th>
                    <th>Windscreen</th>
                    <th>PH&F</th>
                    <th>Courtesy Car Benefit</th>
                    <th>Stamp Duty</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>DIRECTLINE </td>
                    <td> {premium1}</td>
                    <td> {dirbasic_premium}</td>
                    <td> 5000 </td>
                    <td> Free </td>
                    <td> Free </td>
                    <td> {phf}</td>
                    <td> 40 </td>
                    <td>
                      <Button
                        href="https://www.placidinsurance.co.ke/product/directline-comprehensive/">
                        Buy Now
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {premium2 !== null && (
              <Table striped bordered hover className="table3">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Total Premium</th>
                    <th>Basic Premium</th>
                    <th>Excess Protector</th>
                    <th>Windscreen</th>
                    <th>PH&F</th>
                    <th>Courtesy Car Benefit</th>
                    <th>Stamp Duty</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AMACO </td>
                    <td> {premium2}</td>
                    <td> {amacobasic_premium}</td>
                    <td> 5000 </td>
                    <td> Free </td>
                    <td> Free </td>
                    <td> {phf}</td>
                    <td> 40 </td>
                    <td>
                      <Button href="https://www.placidinsurance.co.ke/product/amaco-comprehensive/">
                        Buy Now
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {premium1 !== null && (
              <Table striped bordered hover className="table3">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Total Premium</th>
                    <th>Basic Premium</th>
                    <th>Excess Protector</th>
                    <th>Windscreen</th>
                    <th>PH&F</th>
                    <th>Courtesy Car Benefit</th>
                    <th>Stamp Duty</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>JUBILEE </td>
                    <td> {premium1}</td>
                    <td> {dirbasic_premium}</td>
                    <td> 5000 </td>
                    <td> Free </td>
                    <td> Free </td>
                    <td> {phf}</td>
                    <td> 40 </td>
                    <td>
                      <Button href="https://www.placidinsurance.co.ke/product/jubilee-comprehensive/">
                        Buy Now
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CicInsurance;
